import { get_room_list, post_room_list } from "../../api/index";
export default {
  name: "basetable",
  data: function data() {
    return {
      hoteldatares: {
        keyword: this.keyword,
        page_index: 1,
        page_size: 5
      },
      tableData: [],
      multipleSelection: [],
      delList: [],
      editVisible: false,
      pageTotal: 0,
      form: {},
      idx: -1,
      id: -1,
      addVisible: false
    };
  },
  created: function created() {
    this.getData();
  },
  methods: {
    // 获取 easy-mock 的模拟数据
    getData: function getData() {
      var _this = this;

      get_room_list(this.hoteldatares).then(function (res) {
        _this.tableData = res.data.list;
        _this.pageTotal = res.data.count;
      });
    },
    //创建时间格式化
    timestampToTime: function timestampToTime(row) {
      if (row.create_time == 0) {
        return "-";
      } else {
        var date = new Date(row.create_time * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000

        var Y = date.getFullYear() + '-';
        var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
        var D = (date.getDate() + 1 < 10 ? '0' + (date.getDate() + 1) : date.getDate() + 1) + ' ';
        var h = (date.getHours() + 1 < 10 ? '0' + (date.getHours() + 1) : date.getHours() + 1) + ':';
        var m = (date.getMinutes() + 1 < 10 ? '0' + (date.getMinutes() + 1) : date.getMinutes() + 1) + ':';
        var s = (date.getSeconds() + 1 < 10 ? '0' + (date.getSeconds() + 1) : date.getSeconds() + 1) + ' ';
        return Y + M + D + h + m + s;
      }
    },
    // 触发搜索按钮
    handleSearch: function handleSearch() {
      var _this2 = this;

      get_room_list(this.query).then(function (res) {
        console.log(res);

        _this2.getData();
      });
    },
    // 删除操作
    handleDelete: function handleDelete(index, row) {
      var _this3 = this;

      var addhotelData = {
        type: "delete",
        id: row.id
      }; // 二次确认删除

      this.$confirm("确定要删除吗？", "提示", {
        type: "warning"
      }).then(function () {
        post_room_list(addhotelData).then(function (res) {
          if (res.code == 1) {
            _this3.$message.success(res.msg);

            _this3.getData();
          } else {
            _this3.$message.error(res.msg);
          }
        }); // this.tableData.splice(index, 1);
      }).catch(function () {});
    },
    // 多选操作
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    //新增客房
    addguestroommetnod: function addguestroommetnod() {
      this.$router.push({
        path: "/addguestr"
      });
    },
    // 编辑操作
    handleEdit: function handleEdit(index, row) {
      this.idx = index;
      this.form = row;
      this.$router.push({
        path: "/addguestr",
        query: {
          id: row.id
        }
      });
      this.editVisible = true;
    },
    // 月份价格跳转
    monthlyprice: function monthlyprice(index, row) {
      this.$router.push({
        path: "/monthlyprice",
        query: {
          id: row.id
        }
      });
      this.addVisible = true;
    },
    // 活动价格跳转
    eventprice: function eventprice(index, row) {
      this.$router.push({
        path: "/eventprice",
        query: {
          id: row.id
        }
      });
      this.addVisible = true;
    },
    // 保存编辑
    saveEdit: function saveEdit() {
      this.editVisible = false;
      this.$message.success("\u4FEE\u6539\u7B2C ".concat(this.idx + 1, " \u884C\u6210\u529F"));
      this.$set(this.tableData, this.idx, this.form);
    },
    // 分页导航
    handleCurrentChange: function handleCurrentChange(val) {
      this.hoteldatares.page_index = val;
      console.log(this.hoteldatares.page_index); //点击第几页

      this.getData();
    }
  }
};